// ----------------------------------------
// Login Screen:
//    Login Screen - Login Form
// ----------------------------------------

// -- login screen --
.login-screen {
  @include fixed-header-margin-no-search;
  padding-top: 20px;
  padding-bottom: 20px;
}

.login-screen--background {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: -1;
}

.login-screen--background img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.login-screen--form {
  background-color: $white;
  max-width: 500px;
  border-radius: 10px;
  margin: 60px auto;
  padding: 20px 30px;
}

.login-screen--form .hidden {
  display: none;
}

.login-screen--form h1 {
  @include roboto-bold-34px;
  margin-bottom: 20px;
}

.login-screen--form p {
  @include roboto-reg-15px;
}

.login-screen--form .form-text {
  @include roboto-reg-12px;
  color: $dark-gray;
}

.login-screen--form .form-text a {
  color: $sapphire;
  @include roboto-med-12px;
  text-decoration: none;
}

.login-screen--form .form-error {
  @include roboto-reg-12px;
  color: $dark-red;

  ul {
    padding-left: 0px;
     li {
       list-style-type: none;
     }
  }
}

.login-screen--form ul.errorlist {
  @include roboto-reg-12px;
  color: $dark-red;
  padding-left: 0px;
  margin-top: 10px;

  li {
    list-style-type: none;
  }
}

.login-screen--form-input {
  margin-bottom: 10px;
}

.login-screen--form-input label {
  @include roboto-bold-12px;
}

.login-screen--form-input input {
  @include roboto-reg-15px;
  background-color: $white;
  border: 1px solid $mid-gray;
  border-radius: 3px;
}

.login-screen--form-action {
  margin: 30px 0px;
  display: flex;
  align-items: center;
}

.login-screen--form-action a {
  color: $sapphire;
  text-decoration: none;
  @include roboto-med-12px;
  margin-left: 20px;
}
