// ----------------------------------------
// Dashboard:
//    thinkLibrary Explore Section
// ----------------------------------------

// -- dashboard thinkLibrary explore --
.dash-explore {
  padding-top: 60px;
  padding-bottom: 60px;

  @include for-md-down {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.dash-explore--callout {
  background-color: $sapphire;
  border-radius: 6px;
  padding: 40px;
  min-height: 225px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dash-explore--callout h2 {
  @include roboto-reg-34px;
  color: $white;
}

.dash-explore--callout p {
  @include roboto-reg-15px;
  color: $white;
  max-width: 50%;

  @include for-xs-only {
    max-width: 100%;
  }
}

.dash-explore--callout img {
  width: 285px;
  height: auto;
  position: absolute;
  right: 60px;
  top: -34px;

  @include for-md {
    max-width: 200px;
    top: 10px;
    right: 50px;
  }
  @include for-sm {
    max-width: 200px;
    top: 20px;
    right: 40px;
  }
  @include for-xs-only {
    display: none;
  }
}

.dash-explore--callout .button-secondary {
  margin-top: auto;
  width: 87px;
}