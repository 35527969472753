// ----------------------------------------
// thinkLibrary:
//    Search - No Results
// ----------------------------------------

// -- thinkLibrary search - no results --
.lib-no-results {
  // @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;

  @include for-md-down {
    padding-bottom: 0px;
  }
}

.lib-no-results--callout {
  margin: 20px 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @include for-xl {
    margin: 20px 220px;
  }
  @include for-lg {
    margin: 20px 130px;
  }
  @include for-md-down {
    margin: 20px 0px;
  }
}

.lib-no-results--callout h1 {
  @include roboto-bold-34px;
}

.lib-no-results--callout p {
  @include roboto-reg-22px;
  max-width: 375px;
}

.lib-no-results--callout img {
  width: 430px;
  position: absolute;
  right: 0;
  top: -10px;
  z-index: -1;
  @include for-md-down {
    width: 330px;
  }
  @include for-sm-down {
    display: none;
  }
}