// ----------------------------------------
// Header Styles
// ----------------------------------------

.primary-navbar {
  @extend .navbar;
  @extend .navbar-expand-lg;
  @extend .navbar-light;
  @extend .fixed-top;
  @extend .bg-light;
  padding: 0;
  z-index: 99999;
  min-height: 60px;
  @include for-md-down {
    padding: 8px 0;
  }
}

.primary-navbar .container {
  @extend .h-100;

  #mode-dropdown {
    margin-right: 25px;
  }
}

.thinklaw-brand {
  @extend .h-100;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.thinklaw-brand img {
  height: 40px;
  margin-right: 20px;
}

.thinkportal-logo {
  @extend .h-100;
  border-left: 1px solid $mid-gray;
  padding-left: 20px;
}

.thinkportal-logo span {
  @include montserrat-med-24px;
  color: $dark-red;
}

.thinkportal-logo span:nth-child(2) {
  @include montserrat-xbold-24px;
  color: $darker-gray;
  margin-left: -4px;
}

.thinkportal-logo span:nth-child(3) {
  @include brandon-med-9px;
  color: $darker-gray;
  display: block;
  text-align: right;
  margin-top: -6px;
  text-transform: uppercase;
}

.primary-navbar-links {
  @extend .navbar-nav;
  @extend .me-auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding: 0 200px;

  @include for-lg-down {
    padding: 0 110px;
  }

  @include for-md-down {
    flex-direction: column;
    padding: 20px 0 0 0;
    justify-content: normal;
    align-items: center;
  }
}

.primary-navbar-links > li {
  border-bottom: 3px solid transparent;
  padding: 0 2px;

  @include for-md-down {
    margin: 10px 0;
  }
}

.primary-navbar-links > li > a {
  @include roboto-reg-15px;
  color: $sapphire;
  text-decoration: none;
}

.primary-navbar-links > li > a:hover {
  color: $black;
}

.primary-navbar-links > li.active {
  border-bottom: 3px solid $black;
}

.primary-navbar-links > li.active a {
  color: $black;
}

.profile-link {
  flex: 0 0 auto;
  @include for-md-down {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    justify-content: normal;
    align-items: center;
  }
}

.profile-link > a {
  @include roboto-reg-15px;
  color: $sapphire;
  text-decoration: none;
  display: flex;
  align-items: center;
  fill: $sapphire;
}

.profile-link svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 1px solid $sapphire;
  border-radius: 10px;
}

.profile-link > a:hover {
  color: $black;
  fill: $black;
}

.profile-link > a:hover svg {
  border: 1px solid $black;
}

.profile-link > a.active {
  color: $black;
}

.profile-link > a.active svg {
  fill: $black;
  border: 1px solid $black;
}
