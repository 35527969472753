// ----------------------------------------
// thinkLibrary:
//    Explore the thinkLibrary
// ----------------------------------------

// -- thinkLibrary explore --
.lib-explore {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;

  @include for-md-down {
    padding-top: 20px;
  }
}

.lib-explore--callout {
  margin: 20px 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-xl {
    margin: 20px 220px;
  }
  @include for-lg {
    margin: 20px 130px;
  }
  @include for-md-down {
    margin: 0;
  }
}

.lib-explore--callout h1 {
  @include roboto-reg-34px;
}

.lib-explore--callout p {
  @include roboto-reg-15px;
}

.lib-explore--callout img {
  width: 175px;
  margin-left: 40px;
  @include for-xs-only {
    display: none;
  }
}

.lib-explore--callout .callout-buttons {
  margin-top: 20px;

  a {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.lib-explore--callout .button-pill-teal,
.lib-explore--callout .button-pill-sapphire{
  margin-right: 10px;
}

.lib-explore--callout .button-pill-teal svg,
.lib-explore--callout .button-pill-orange svg,
.lib-explore--callout .button-pill-sapphire svg {
  width: 10px;
  height: 4px;
  margin-right: 4px;
}
