// ------------------------------------------
// Icons Styles (usually for svgs)
// ------------------------------------------

// -- icon - 20px --
@mixin icon-20px {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
// -- icon - 20px - icon on right side--
@mixin icon-20px-right {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}


// -- icon - 18px --
@mixin icon-18px {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

// -- icon - 16px --
@mixin icon-16px {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

// -- icon - 14px --
@mixin icon-14px {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

// -- icon - 10px --
@mixin icon-10px {
  width: 10px;
  height: 10px;
  margin-right: 6px;
}