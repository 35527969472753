// ----------------------------------------
// @font-face Declarations
// ----------------------------------------

* {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Montserrat Medium */
@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/montserrat-medium.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-medium.woff") format("woff");
}

/* Montserrat ExtraBold */
@font-face {
  font-family: "Montserrat ExtraBold";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/montserrat-extrabold.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-extrabold.woff") format("woff");
}

/* Brandon Grotesque Medium */
@font-face {
  font-family: "Brandon Grotesque Medium";
  src: local("Brandon Grotesque Medium"), local("Brandon-Grotesque-Medium"),
    url("../fonts/brandon-grotesque/brandon-grotesque-medium.woff2")
      format("woff2"),
    url("../fonts/brandon-grotesque/brandon-grotesque-medium.woff")
      format("woff");
}

/* Brandon Grotesque Black */
@font-face {
  font-family: "Brandon Grotesque Black";
  src: local("MontsBrandon Grotesqueerrat Black"),
    local("Brandon-Grotesque-Black"),
    url("../fonts/brandon-grotesque/brandon-grotesque-black.woff2")
      format("woff2"),
    url("../fonts/brandon-grotesque/brandon-grotesque-black.woff")
      format("woff");
}

/* Roboto Light */
@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"), local("Roboto-Light"),
    url("../fonts/roboto/roboto-light.woff2") format("woff2"),
    url("../fonts/roboto/roboto-light.woff") format("woff");
}

/* Roboto Regular */
@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"), local("Roboto-Regular"),
    url("../fonts/roboto/roboto-regular.woff2") format("woff2"),
    url("../fonts/roboto/roboto-regular.woff") format("woff");
}

/* Roboto Medium */
@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("../fonts/roboto/roboto-medium.woff2") format("woff2"),
    url("../fonts/roboto/roboto-medium.woff") format("woff");
}

/* Roboto Bold */
@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("../fonts/roboto/roboto-bold.woff2") format("woff2"),
    url("../fonts/roboto/roboto-bold.woff") format("woff");
}
