// ----------------------------------------
// thinkSupport:
//    thinkSupport Content
// ----------------------------------------

// -- thinksupport content --
.thinksupport {
  @include fixed-header-margin;
}

.thinksupport--wrapper {
  @extend .d-flex;
  overflow-x: hidden;
}

.thinksupport--content {
  min-width: 100vw;

  @include for-sm-up {
    min-width: 0;
    width: 100%;
  }
}

.thinksupport--toggle-nav {
  @extend .navbar;
  @extend .navbar-expand-lg;
  @extend .navbar-light;
  border-bottom: 1px solid $lighter-gray;
  padding-left: 20px;
  @include for-sm-up {
    display: none;
  }
}

.thinksupport--content-block {
  padding: 20px;
}

.thinksupport--content-block h1 {
  background-color: $icy-blue;
  @include roboto-bold-34px;
  padding: 4px 10px 6px 10px;
}

.thinksupport--content-block .block-section,
.thinksupport--content-block .block-content {
  padding: 10px;

  dl dt,
  h3 {
    @include roboto-bold-22px;
    margin-bottom: 10px;
    font-weight: inherit
  }

  dl dd,
  p,
  ol li,
  ul li {
    @include roboto-reg-15px;
  }

  a {
    @include roboto-med-15px;
    color: $sapphire;
    text-decoration: none;
  }

  img {
    margin: 0px auto;
    display: block;
  }
}