// ----------------------------------------
// Color Variables
// ----------------------------------------

// --- THINKLAW COLORS ---
// primary palette
$white: #fff;
$black: #000;
$sapphire: #006eb6;
$lagoon-teal: #01565c;
$southwestern-orange: #cc4c15;

// secondary palette
$deep-blueberry: #004e82;
$icy-blue: #d6efff;
$cactus-green: #b5b412;
$dark-gray: #6e6e6e;
$mid-gray: #b8b8b8;
$pale-gray: #f5f5f5;

// --- NON-THINKLAW COLORS ---
// utility palette
$dark-red: #c12727;
$lighter-gray: #e6e6e6;
$darker-gray: #555555;

// activities palette
$christalle: #34015C;
