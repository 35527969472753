// ----------------------------------------
// Login Screen:
//    Terms and Conditions (follows login)
// ----------------------------------------

// -- terms and conditions --
.terms-and-conditions {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 60px;
}

.terms-and-conditions--content {
  margin-top: 20px;
  margin-bottom: 40px;
}

.terms-and-conditions h1 {
  @include roboto-bold-34px;
}

.terms-and-conditions h2 {
  @include roboto-bold-22px;
}

.terms-and-conditions h3 {
  @include roboto-med-19px;
}

.terms-and-conditions h4 {
  @include roboto-med-15px;
}

.terms-and-conditions p,
.terms-and-conditions ul li,
.terms-and-conditions ol li {
  @include roboto-reg-15px;
}


.terms-and-conditions a {
  color: $sapphire;
  text-decoration: none;
  @include roboto-med-15px;
}

.terms-and-conditions img.full-width {
  width: 100%;
  object-fit: contain;
  @include for-sm-down {
    height: auto;
  }
}

.terms-and-conditions img.left {
  display: block;
  margin-right: auto;
  @include for-sm-down {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.terms-and-conditions img.right {
  display: block;
  margin-left: auto;
  @include for-sm-down {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}