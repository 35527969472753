// ----------------------------------------
// Onboarding:
//    Step Two Section
// ----------------------------------------

// -- onboarding step two --
.onb-s2 {
  padding-top: 0px;
  padding-bottom: 20px;

  @include for-md-down {
    padding-top: 20px;
  }
}

.onb-s2 h2 {
  @include roboto-reg-22px;
  color: $deep-blueberry;
  display: flex;
  margin: 10px 40px;

  @include for-lg {
    margin: 10px 30px;
  }

  @include for-xs-only {
    margin: 0px;
    padding: 10px 20px;
  }
}

.onb-s2 h2 .number {
  @include brandon-black-49px;
  margin-right: 20px;
}

.onb-s2--info {
  @extend .col-lg-4;
  display: flex;
  border-right: 2px solid $icy-blue;
  margin: 20px 0px;
  padding: 0px 40px;

  @include for-lg {
    flex-direction: column;
    margin-bottom: 0px;
  }
  @include for-md-down {
    padding: 0px 50px !important;
    border: none;
    align-items: center;
  }
  @include for-xs-only {
    flex-direction: column;
    margin: 20px 0px 0px 0px;
    align-items: normal;
  }
}

.onb-s2--info:nth-child(2) {
  padding: 0px 40px 0px 50px;

  @include for-lg {
    padding: 0px 40px;
  }
}

.onb-s2--info:last-child {
  border-right: none;
}

.onb-s2--info .s2-info-image {
  margin-right: 14px;

  @include for-xs-only {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  img {
    width: 130px;
    height: auto;

    @include for-xl {
      width: 95px;
    }
  }
}

.onb-s2--info .s2-info-text h3 {
  @include roboto-reg-22px;
  color: $black;
  @include for-lg {
    margin-top: 10px;
  }
}

.onb-s2--info .s2-info-text p {
  @include roboto-reg-12px;
  color: $black;
}
