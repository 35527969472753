// ----------------------------------------
// thinkLibrary:
//    Search Results: Other Results
// ----------------------------------------

// -- thinkLibrary search - other results --
.lib-results-other {
  padding-top: 20px;
  padding-bottom: 20px;
}

.lib-results-other--cards h2 {
  background-color: $icy-blue;
  @include roboto-reg-12px;
  display: flex;
  align-items: center;
  padding: 3px 14px;

  svg {
    @include icon-10px;
  }
}

.lib-results-other--container {
  margin: 0px 40px 10px 40px;
}

.lib-results-other--container .card {
  border: 1px solid $mid-gray;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px 0px;
  min-height: 80px;
  justify-content: space-between;

  @include for-md-down {
    display: block;
    padding: 14px 24px 20px 24px;
  }
}

.lib-results-other--left {
  display: flex;
  flex-direction: column;
}

.lib-results-other--left h3 {
  @include roboto-bold-22px;
  color: $black;
}

.lib-results-other--left p {
  @include roboto-reg-12px;
  margin-bottom: 0px;
  padding-right: 20px;

  @include for-md-down {
    padding-bottom: 14px;
  }
}

.lib-results-other--right {
  display: flex;
  align-items: center;
}

.lib-results-other--right .button-primary-small {
  margin-left: auto;
}

.lib-results-other--right .button-primary-small svg {
  @include icon-20px-right;
  fill: $white !important;
}
