// ----------------------------------------
// Dashboard:
//    Getting Started Tutorial Section
// ----------------------------------------

// -- dashboard getting started tutorial --
.dash-tutorial {
  padding-top: 20px;
  padding-bottom: 0px;
}

.dash-tutorial--callout {
  display: flex;
  border: 1px solid $mid-gray;
  border-bottom: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 10px;
  align-items: center;

  @include for-md-down {
    position: relative;
    display: block;
    padding: 40px;
  }
}

.dash-tutorial--callout > div {
  @include for-lg {
    max-width: 500px;
  }
}

.dash-tutorial--callout img {
  width: 240px;
  margin-right: 40px;

  @include for-lg {
    width: 175px;
  }
  @include for-md-down {
    position: absolute;
    right: 0px;
    top: 60px;
  }
  @include for-sm {
    top: 80px;
    width: 200px;
  }
  @include for-xs-only {
    display: none;
  }
}

.dash-tutorial--callout h2 {
  @include roboto-reg-22px;
  color: $deep-blueberry;
}

.dash-tutorial--callout p {
  @include roboto-reg-15px;
  color: $black;
  margin-right: 10px;
  margin-bottom: 0px;

  @include for-md-down {
    margin-bottom: 16px;
    max-width: 55%;
  }
  @include for-xs-only {
    max-width: 100%;
  }
}

.dash-tutorial--callout .button-secondary {
  margin-left: auto;
  margin-right: 40px;
}