// ----------------------------------------
// thinkLibrary:
//    Search Results: Activity Results
// ----------------------------------------

// -- thinkLibrary search - activity results --
.lib-results-activity {
  padding-top: 20px;
  padding-bottom: 0px;
}

.lib-results-activity--cards h2 {
  background-color: $icy-blue;
  @include roboto-reg-12px;
  display: flex;
  align-items: center;
  padding: 3px 14px;

  svg {
    @include icon-10px;
  }
}

.lib-results-activity--container {
  margin: 0px 40px 10px 40px;
}

.lib-results-activity--container .card {
  border: 1px solid $mid-gray;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px 0px;
  min-height: 80px;

  @include for-md-down {
    display: block;
  }
}

.lib-results-activity--container .card > svg {
  width: 30px;
  height: 30px;
  fill: $christalle;
  margin-left: -25px;
  margin-top: 4px;
  margin-right: 10px;
}

.lib-results-activity--left {
  width: 49%;

  @include for-md-down {
    width: 100%;
    display: block;
    margin-top: -30px;
    margin-left: 14px;
  }
}

.lib-results-activity--left h3 {
  @include roboto-bold-22px;
  color: $christalle;
}

.lib-results-activity--left h3 + div {
  @include roboto-reg-12px;
}

.lib-results-activity--left h3 + div span:first-child {
  @include roboto-med-12px;
}

.lib-results-activity--left h3 + div span:nth-child(2) {
  @include roboto-reg-12px;
  color: $christalle;
}

.lib-results-activity--right {
  width: 51%;
  border-left: 1px solid $mid-gray;
  display: flex;
  align-items: center;

  @include for-md-down {
    border: none;
    width: 100%;
    display: block;
    margin-left: 14px;
    padding-bottom: 10px;
  }
}

.lib-results-activity--right p {
  @include roboto-reg-15px;
  margin-bottom: 0px;
  padding: 0px 20px;

  @include for-md-down {
    padding: 10px 30px 10px 0px;
  }
}

.lib-results-activity--right .button-primary-small {
  margin-left: auto;
}

.lib-results-activity--right .button-primary-small svg {
  @include icon-20px-right;
  fill: $white !important;
}
