// ----------------------------------------
// Typography Mixins and Classes
// ----------------------------------------

// ---  ROBOTO REGULAR ---

// Roboto Regular - 34px
@mixin roboto-reg-34px {
  font-family: "Roboto Regular", Helvetica, sans-serif;
  font-size: 34px;
  line-height: 40px;
}

// Roboto Regular - 22px
@mixin roboto-reg-22px {
  font-family: "Roboto Regular", Helvetica, sans-serif;
  font-size: 22px;
  line-height: 28px;
}

// Roboto Regular - 19px
@mixin roboto-reg-19px {
  font-family: "Roboto Regular", Helvetica, sans-serif;
  font-size: 19px;
  line-height: 25px;
}

// Roboto Regular - 15px
@mixin roboto-reg-15px {
  font-family: "Roboto Regular", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 21px;
}

// Roboto Regular - 12px
@mixin roboto-reg-12px {
  font-family: "Roboto Regular", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
}

// ---  ROBOTO MEDIUM ---

// Roboto Medium - 27px
@mixin roboto-med-27px {
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 27px;
  line-height: 33px;
}

// Roboto Medium - 19px
@mixin roboto-med-19px {
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 19px;
  line-height: 25px;
}

// Roboto Medium - 15px
@mixin roboto-med-15px {
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 21px;
}

// Roboto Medium - 12px
@mixin roboto-med-12px {
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
}

// ---  ROBOTO BOLD ---

// Roboto Bold - 80px
@mixin roboto-bold-80px {
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 80px;
}

// Roboto Bold - 34px
@mixin roboto-bold-34px {
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 34px;
  line-height: 40px;
}

// Roboto Bold - 22px
@mixin roboto-bold-22px {
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 22px;
  line-height: 28px;
}

// Roboto Bold - 15px
@mixin roboto-bold-15px {
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 21px;
}

// Roboto Bold - 12px
@mixin roboto-bold-12px {
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
}

// --- ROBOTO LIGHT ---
// Roboto Light - 22px
@mixin roboto-light-22px {
  font-family: "Roboto Light", Helvetica, sans-serif;
  font-size: 22px;
  line-height: 28px;
}

// ---  BRANDON GROTESQUE MEDIUM ---

// Brandon Grotesque Medium - 19px
@mixin brandon-med-19px {
  font-family: "Brandon Grotesque Medium", Helvetica, sans-serif;
  font-size: 19px;
  line-height: 25px;
}

// Brandon Grotesque Medium - 9px
@mixin brandon-med-9px {
  font-family: "Brandon Grotesque Medium", Helvetica, sans-serif;
  font-size: 9px;
}

// ---  BRANDON GROTESQUE BLACK ---

// Brandon Grotesque Black - 49px
@mixin brandon-black-49px {
  font-family: "Brandon Grotesque Black", Helvetica, sans-serif;
  font-size: 49px;
}

// Brandon Grotesque Black - 19px
@mixin brandon-black-19px {
  font-family: "Brandon Grotesque Black", Helvetica, sans-serif;
  font-size: 19px;
  line-height: 25px;
}

// ---  MONTSERRAT MEDIUM ---

// Montserrat Medium - 24px
@mixin montserrat-med-24px {
  font-family: "Montserrat Medium", Helvetica, sans-serif;
  font-size: 24px;
}

// ---  MONTSERRAT EXTRABOLD ---

// Montserrat ExtraBold - 24px
@mixin montserrat-xbold-24px {
  font-family: "Montserrat ExtraBold", Helvetica, sans-serif;
  font-size: 24px;
}

h1 {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 34px;
}

h2 {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 28px;
}

h3 {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 19px;
  line-height: 24px;
}

h4 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 19px;
  line-height: 24px;
}

h5 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 21px;
}