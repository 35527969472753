// ----------------------------------------
// Very Basic Styles
// ----------------------------------------

html,
body {
  height: 100%;
  color: $black;
}

#wrapper {
  min-height: 100%;
}
