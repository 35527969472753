// -------------------------------------------------
// Error Pages:
//    Browser Checker - out of date browser message
// -------------------------------------------------

// -- browser-update.org overwrites --
#buorg.buorg {
  background-color: $icy-blue;
  border-top: 1px solid $icy-blue;
  border-bottom: 1px solid $sapphire;
  position: fixed;
  top: 0px;
}
.buorg-pad {
  padding: 30px 80px !important;
  line-height: 1.2 !important;
}
.buorg-buttons {
  margin: 30px 15px 0 !important;
}
.buorg-icon {
  display: none !important;
}
.buorg-dismiss {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 33%;
  right: 5%;
  z-index: 1;
}

#buorg ~ #wrapper {
  margin-top: -105px;
}