// ----------------------------------------
// Lesson:
//    Lesson Sidebar
// ----------------------------------------

// -- lesson sidebar --
.lesson--sidebar {
  @extend .col-lg-3;
  background-color: $pale-gray;
  border-left: 1px solid $mid-gray;
  padding: 20px;

  @include for-md-down {
    border-left: none;
    margin: 40px 0px 20px 0px;
  }

  @include for-xs-only {
    border-left: none;
    margin-bottom: 0px;
  }
}

.lesson--sidebar h2 {
  @include roboto-reg-22px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.lesson--sidebar h2 svg {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

// themes shortcut
.lesson--sidebar .themes-shortcut h2 svg {
  fill: $lagoon-teal;
}

.lesson--sidebar .themes-shortcut {
  margin-bottom: 40px;
}

.lesson--sidebar .themes-shortcut--pill {
  @include roboto-reg-15px;
  background-color: $white;
  border: 1px solid $lagoon-teal;
  border-radius: 16px;
  padding: 2px 12px;
  color: $lagoon-teal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 8px;
  text-decoration: none;
}


// two lists of standards
.lesson--sidebar .standards ul {
  columns: 2;

  @include for-md-down {
    margin-bottom: 0px;
  }
}

.lesson--sidebar .standards ul li {
  @include roboto-med-12px;
  margin-bottom: 10px;
}