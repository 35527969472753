// ----------------------------------------
// Onboarding:
//    Step One Section
// ----------------------------------------

// -- onboarding step one --
.onb-s1 {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;

  @include for-md-down {
    padding-top: 12px;
  }
}

.onb-s1--welcome {
  @extend .col-lg-4;
  padding-right: 0px;
  padding-top: 16px;
  padding-bottom: 16px;

  @include for-lg {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @include for-md-down {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 12px;
  }

  a {
    color: $white;
    &:hover {
    color: #e5f5ff;

    }
  }
}

.onb-s1--welcome > div {
  background-color: $deep-blueberry;
  color: $white;
  height: 100%;
  padding: 30px 40px;

  @include for-lg {
    padding: 20px 30px;
  }
}

.onb-s1--welcome h1 {
  @include roboto-reg-34px;

  @include for-lg {
    font-size: 26px;
    line-height: 34px;
  }
}

.onb-s1--welcome h2 {
  @include roboto-bold-22px;
  color: $icy-blue;
  display: flex;
  margin: 30px 0px;

  @include for-lg {
    margin: 20px 0px;
  }
}

.onb-s1--welcome h2 .number {
  @include brandon-black-49px;
  margin-right: 20px;
}

.onb-s1--welcome p {
  @include roboto-reg-15px;
}

.onb-s1--video {
  @extend .col-lg-8;
  padding-left: 0px;
  display: flex;
  align-items: center;

  @include for-md-down {
    padding-left: 12px;
  }
}

.onb-s1--video .video-ratio {
  @extend .ratio;
  @extend .ratio-16x9;
}
