// ----------------------------------------
// Lesson:
//    Lesson Content
// ----------------------------------------

// -- lesson wrapper --
.lesson {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;

  @include for-md-down {
    padding-top: 12px;
  }

  @include for-xs-only {
    padding-bottom: 0px;
  }
}

// -- lesson content --
.lesson--content {
  @extend .col-lg-9;
  padding-right: 30px;

  @include for-md-down {
    padding: 0px;
  }
  @include for-xs-only {
    padding: 0px 14px;
  }
}

.lesson--content h1 {
  @include roboto-bold-34px;
  color: $southwestern-orange;
  display: flex;
  align-items: center;
}

.lesson--content h1 svg {
  fill: $southwestern-orange;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.lesson--content .content-details {
  background-color: $pale-gray;
  margin: 10px 0px 20px 0px;
  @include roboto-reg-12px;
  padding: 4px 10px;

  > span {
    color: $southwestern-orange;
  }
}

.lesson--content .content-description {
  @include roboto-reg-15px;
}


// lesson content - materials container with cards
.lesson--content .content--materials {
  border: 1px solid $mid-gray;
  border-radius: 6px;
  padding: 10px 20px 0px 20px;
  margin-top: 30px;
}

.lesson--content .content--teacher-materials,
.lesson--content .content--student-materials {
  @extend .col-lg-6;
}

.lesson--content .content--teacher-materials {
  padding-right: 20px;
  border-right: 1px solid $mid-gray;

  @include for-md-down {
    padding-right: 14px;
    border: none;
  }
}

.lesson--content .content--student-materials {
  padding-left: 20px;

  @include for-md-down {
    padding-left: 14px;
  }
}

.lesson--content .content--teacher-materials h2,
.lesson--content .content--student-materials h2 {
  @include roboto-bold-22px;
  margin-bottom: 10px;
}

.lesson--content .content--student-materials h2 {
  @include for-md-down {
    margin-top: 20px;
  }
}

.lesson--content .content--teacher-materials .row,
.lesson--content .content--student-materials .row {
  @extend .row-cols-1;
  @extend .row-cols-md-2;
  @extend .gx-2;
  @extend .gy-3;
}

.lesson--content .content--teacher-materials .card,
.lesson--content .content--student-materials .card {
  @extend .h-100;
  border: 1px solid $mid-gray;
  border-radius: 6px;
}

.lesson--content .content--teacher-materials .card > a.card-img,
.lesson--content .content--student-materials .card > a.card-img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  max-height: 115px;
  text-align: center;
  background-color: $icy-blue;
  padding: 8px;
  position: relative;
  overflow: hidden;

  > img {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3))
            drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
    height: 100%;
    object-fit: contain;
  }

  .file-type {
    position: absolute;
    right: 0px;
    bottom: 4px;
    background-color: $white;
    text-decoration: none;
    color: $black;
    padding: 2px 8px;
    @include roboto-bold-15px;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
  }
}

.lesson--content .content--teacher-materials .card-body,
.lesson--content .content--student-materials .card-body {
  padding: 10px 1rem;
}

.lesson--content .content--teacher-materials .card-body h3,
.lesson--content .content--student-materials .card-body h3 {
  @include roboto-bold-15px;
}

.lesson--content .content--teacher-materials .card-footer,
.lesson--content .content--student-materials .card-footer {
  background-color: $white;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lesson--content .content--teacher-materials .card-footer a,
.lesson--content .content--student-materials .card-footer a {
  @include for-xl-down {
    width: 100%;
    margin-top: 4px;
  }
}

.lesson--content .content--teacher-materials .button-secondary-small svg,
.lesson--content .content--student-materials .button-secondary-small svg {
  @include icon-14px;
}



// lesson content -- footer with button
.lesson--content .content--footer {
  background-color: $icy-blue;
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @include for-sm-down {
    display: block;
  }
}

.lesson--content .content--footer h3 {
  @include roboto-reg-22px;
  margin: 0px;

  @include for-sm-down {
    margin-bottom: 10px;
  }
}

.lesson--content .content--footer svg {
  @include icon-18px;
}

// activities
.lesson--content {
  &.content--activity {
    h1 {
      color: $christalle;

      svg {
        fill: $christalle;
      }
    }
    
    .content-details {
      > span {
        color: $christalle;
      }
    }

    .content--footer {
      .lesson-now {
        display: none;
      }

      .button-primary-lead {
        margin-left: auto;
      }
    }
  }
}
