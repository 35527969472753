// ----------------------------------------
// Onboarding:
//    Step Three Section
// ----------------------------------------

// -- onboarding step three --
.onb-s3 {
  padding-top: 20px;
  padding-bottom: 60px;

  @include for-md-down {
    padding-top: 0px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.onb-s3 .onb-s3--info h2 {
  @include roboto-reg-22px;
  color: $deep-blueberry;
  margin: 10px 0px;
  display: flex;

  @include for-xs-only {
    margin: 0px;
    padding: 10px 0px;
  }
}

.onb-s3 .onb-s3--info h2 .number {
  @include brandon-black-49px;
  margin-right: 20px;
}

.onb-s3--info {
  @extend .col-xl-4;
  @extend .col-lg-5;
  display: flex;
  flex-direction: column;
  padding: 0px 38px;

  @include for-lg {
    padding: 0px 28px;
  }

  @include for-xs-only {
    padding: 0px;
  }
}

.onb-s3--info .s3-info-box {
  display: flex;
  margin: 20px 0px;

  @include for-md-down {
    align-items: center;
    margin: 20px 0px 60px 0px;
  }

  @include for-xs-only {
    margin: 20px 30px 40px 30px;
  }
}

.onb-s3--info .s3-info-image {
  margin-right: 14px;

  img {
    width: 95px;
    height: auto;

    @include for-md-down {
      width: 130px;
    }

    @include for-xs-only {
      width: 95px;
    }
  }
}

.onb-s3--info .s3-info-text p {
  @include roboto-reg-12px;
  color: $black;
}

.onb-s3--info .button-secondary svg {
  @include icon-16px;
}

// onboard launch thinkPortal
.onb-s3--launch {
  @extend .col-xl-8;
  @extend .col-lg-7;
  background-color: $sapphire;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  color: $white;
  fill: $white;
  text-decoration: none;
}

.onb-s3--launch:hover {
  color: $icy-blue;
  fill: $icy-blue;
}

.onb-s3--launch svg {
  width: 135px;
  height: 135px;
}

.onb-s3--launch .s3-launch-text {
  margin-right: 10px;
}

.onb-s3--launch .s3-launch-text h2 {
  @include roboto-reg-34px;
}

.onb-s3--launch .s3-launch-text p {
  @include roboto-reg-15px;
}
