// ----------------------------------------
// thinkLibrary:
//    Search - New Search Banner
// ----------------------------------------

// -- thinkLibrary search - new search banner --
.lib-new-search {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 60px;
  margin-bottom: 20px;
}

.lib-new-search--banner {
  background: $deep-blueberry;
  display: flex;
  align-items: center;
  min-height: 92px;
  @include for-md-down {
    padding: 20px;
  }
}

.lib-new-search--banner img {
  max-width: 208px;
  margin-top: -40px;
  margin-bottom: -40px;
  margin-right: 40px;
  margin-left: 50px;

  @include for-md-down {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -10px;
    margin-bottom: -10px;
    max-width: 130px;
  }
  @include for-xs-only {
    display: none;
  }
}

.lib-new-search--banner .banner-details h2 {
  color: $white;
  @include roboto-med-27px;
  margin-bottom: 0px;
}

.lib-new-search--banner .banner-details p {
  color: $white;
  @include roboto-reg-15px;
  margin-bottom: 10px;
}