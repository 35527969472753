// ----------------------------------------
// Dashboard:
//     Trending Now Section
// ----------------------------------------

// -- dashboard trending now --
.dash-trending {
  @include fixed-header-margin;
  background-color: $icy-blue;
  padding-top: 20px;
  padding-bottom: 40px;

  @include for-md-down {
    padding-top: 12px;
  }
}

.dash-trending h2 {
  @include roboto-bold-34px;
  color: $black;
  margin: 0px 0px 16px 0px;
}

// dashboard trending now - primary card
.dash-trending--primary {
  @extend .col-lg-12;
}

.dash-trending--primary .card {
  height: 100%;
  border: 1px solid $mid-gray;
  border-radius: 3px;
}

.dash-trending--primary .dash-trending--card {
  display: flex;
  align-items: center;

  @include for-md-down {
    display: block;
  }
}

.dash-trending--primary .dash-trending--card img {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 100%;
  object-fit: cover;
}

.dash-trending--primary .dash-trending--card .card-body {
  padding: 16px 30px;
}

.dash-trending--primary .dash-trending--card .card-content {
  height: 100%;
  border: 1px solid;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

// dashboard trending now - secondary cards
.dash-trending--secondary {
  @extend .col-lg-4;
  margin-top: 16px;
}

.dash-trending--secondary .card {
  height: 100%;

  &.trending-theme {
    border: 1px solid $lagoon-teal;
  }
  &.trending-lesson {
    border: 1px solid $southwestern-orange;
  }
  &.trending-material {
    border: 1px solid $black;
  }
}

.dash-trending--secondary .dash-trending--card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dash-trending--secondary .card-header {
  background-color: $white;
  border: none;
  padding: 1rem;
}

.dash-trending--secondary .card-body {
  padding: 0px 1rem;
}

.dash-trending--secondary .card-footer {
  padding: 0px 1rem  1rem 1rem;
  @include roboto-med-12px;
}

// dashboard trending now - card styles - both primary and secondary
.dash-trending--card h3 {
  @include roboto-bold-22px;
  margin-bottom: 0px;
}
.trending-theme .dash-trending--card h3 {
  color: $lagoon-teal;
}
.trending-lesson .dash-trending--card h3 {
  color: $southwestern-orange;
}
.trending-material .dash-trending--card h3 {
  color: $black;
}

.dash-trending--card .card-details {
  background-color: $pale-gray;
  width: 100%;
  @include roboto-med-12px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}
.dash-trending--primary .dash-trending--card .card-details {
  margin: 16px 0px;
}
.dash-trending--secondary .dash-trending--card .card-details {
  margin: 0px 0px 16px 0px;
}

.dash-trending--card .card-details svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.trending-theme .dash-trending--card svg {
  fill: $lagoon-teal;
}
.trending-lesson .dash-trending--card svg {
  fill: $southwestern-orange;
}
.trending-material .dash-trending--card svg {
  fill: $black;
}

.dash-trending--card .card-details .detail-type {
  text-transform: capitalize;
  @include roboto-med-12px;
}
.trending-lesson .dash-trending--card .card-details .detail-type {
  color: $southwestern-orange;
}
.trending-theme .dash-trending--card .card-details .detail-type {
  color: $lagoon-teal;
}
.trending-material .dash-trending--card .card-details .detail-type {
  color: $black;
}

.dash-trending--primary .dash-trending--card .card-description {
  @include roboto-reg-15px;
}
.dash-trending--secondary .dash-trending--card .card-description {
  @include roboto-reg-12px;
}

.dash-trending--card .card-footer {
  background-color: $white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-trending--card .card-footer-text {
  @include roboto-med-12px;
}

.dash-trending--primary .dash-trending--card .card-view-button {
  @include for-sm-down {
    width: 100%;
  }
}
