// ----------------------------------------
// Button Styles
// ----------------------------------------

// --button primary mixin--
// -sapphire background -white text - sapphire border-
@mixin button-primary {
  background-color: $sapphire;
  border: 1px solid $sapphire;
  color: $white;
  fill: $white;
  font-family: "Roboto Bold", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  padding: 0 22px;
}

@mixin button-primary-hover {
  color: $white;
  border: 1px solid $deep-blueberry;
  background-color: $deep-blueberry;
}

@mixin button-primary-lead {
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 17px;
  line-height: 23px;
}

@mixin button-primary-small {
  padding: 0 12px;
}

.button-primary {
  @extend .btn;
  @include button-primary;
}

.button-primary:hover {
  @include button-primary-hover;
}

.button-primary-lead {
  @extend .btn;
  @include button-primary;
  @include button-primary-lead;
}

.button-primary-lead:hover {
  @include button-primary-hover;
}

.button-primary-small {
  @extend .btn;
  @include button-primary;
  @include button-primary-small;
}

.button-primary-small:hover {
  @include button-primary-hover;
}


// --button secondary--
// -white background - sapphire text - sapphire border-
@mixin button-secondary {
  background-color: $white;
  border: 1px solid $sapphire;
  color: $sapphire;
  fill: $sapphire;
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  padding: 0 22px;
}

@mixin button-secondary-hover {
  color: $sapphire;
  border: 1px solid $sapphire;
  background-color: lighten($icy-blue, 3%);
}

@mixin button-secondary-small {
  min-height: 26px;
  padding: 0 12px;
}

.button-secondary {
  @extend .btn;
  @include button-secondary;
}

.button-secondary:hover {
  @include button-secondary-hover;
}

.button-secondary-small {
  @extend .btn;
  @include button-secondary;
  @include button-secondary-small;
}

.button-secondary-small:hover {
  @include button-secondary-hover;
}

// button pill mixin
@mixin button-pill {
  border: 1px solid $black;
  border-radius: 18px;
  color: $black;
  fill: $black;
  font-family: "Roboto Medium", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  width: 140px;
}

// button pill -teal
.button-pill-teal {
  @extend .btn;
  @include button-pill;
  border: 1px solid $lagoon-teal;
  color: $lagoon-teal;
  fill: $lagoon-teal;
}
.button-pill-teal:hover {
  border: 1px solid lighten($lagoon-teal, 10%);
  color: lighten($lagoon-teal, 10%);
  fill: lighten($lagoon-teal, 10%);
}

// button pill -orange
.button-pill-orange {
  @extend .btn;
  @include button-pill;
  border: 1px solid $southwestern-orange;
  color: $southwestern-orange;
  fill: $southwestern-orange;
}
.button-pill-orange:hover {
  border: 1px solid lighten($southwestern-orange, 10%);
  color: lighten($southwestern-orange, 10%);
  fill: lighten($southwestern-orange, 10%);
}

// button pill -sapphire
.button-pill-sapphire {
  @extend .btn;
  @include button-pill;
  border: 1px solid $sapphire;
  color: $sapphire;
  fill: $sapphire;
}
.button-pill-sapphire:hover {
  border: 1px solid lighten($sapphire, 10%);
  color: lighten($sapphire, 10%);
  fill: lighten($sapphire, 10%);
}
