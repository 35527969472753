// ----------------------------------------
// thinkLibrary:
//    All thinkThemes
// ----------------------------------------

// -- thinkThemes list of themes --
.lib-themes .anchor,
.lib-subjects .anchor {
  @include fixed-header-anchor;
}

.lib-themes--list h2,
.lib-subjects--list h2 {
  background-color: $icy-blue;
  @include roboto-reg-12px;
  display: flex;
  align-items: center;
  padding: 3px 14px;

  svg {
    @include icon-10px;
  }
}

.lib-themes--list ul,
.lib-subjects--list ul {
  background-color: $pale-gray;
  margin: 0 40px 40px 40px;
  column-count: 4;
  padding: 30px 20px;

  @include for-md {
    column-count: 3;
  }
  @include for-sm {
    column-count: 2;
  }
  @include for-xs-only {
    column-count: 1;
  }
}

.lib-themes--list ul li,
.lib-subjects--list ul li {
  margin: 0 20px 16px 20px;
  list-style-type: none;
  color: $lagoon-teal;
  @include brandon-med-19px;

  a {
    color: $lagoon-teal;
    text-decoration: none;
    @include brandon-med-19px;

    &:hover {
      color: lighten($lagoon-teal, 10%);
    }
  }

  span {
    @include brandon-black-19px;
  }
}

// -- thinkSubjects color differences --
.lib-subjects--list ul li {
  a {
    color: $sapphire;

    &:hover {
      color: lighten($sapphire, 10%);
    }
  }
}
