// ----------------------------------------
// Search Bar Styles
// ----------------------------------------

.search-bar {
  @extend .navbar;
  @extend .fixed-top;
  @extend .navbar-expand-lg;
  @extend .navbar-dark;
  background-color: $sapphire;
  color: $white;
  min-height: 55px;
  margin-top: 60px;
}

.search-bar-collapse {
  @extend .navbar-nav;
  display: flex;
  width: 100%;
}

.search-bar-collapse .container {
  background-color: $deep-blueberry;
  height: 43px;
  margin-top: -2px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-left: 0px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 50%;
    z-index: -1;
    height: 43px;
    background-color: $deep-blueberry;
  }
}

.search-bar--actionbar {
  display: flex;
  align-items: center;
  height: 43px;
}

// search by keyword input
.search-bar--keywords {
  height: 23px;
  width: 465px;
  @include roboto-reg-12px;
  color: $darker-gray;
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid $white;
  margin-top: 1px;

  @include for-xl {
    width: 425px;
  }
  @include for-lg {
    width: 280px;
  }
}

// search by grades checkbox dropdown
.search-bar--grades {
  position: relative;
  display: inline-block;
}

.search-bar--grades-input {
  height: 23px;
  width: 280px;
  cursor: pointer;
  @include roboto-reg-12px;
  color: $darker-gray;
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid $white;
}

.search-bar--grades-list {
  position: absolute;
  top: 25px;
  left: 0;
  background: $white;
  color: $black;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  width: 280px;
}

.search-bar--grades-list ul {
  padding: 10px 20px;
}

.search-bar--grades-list ul li {
  list-style-type: none;
  @include roboto-reg-12px;
  color: $darker-gray;
  padding: 6px 0px;
  border-bottom: 1px solid $icy-blue;
}

.search-bar--grades-list ul li label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.search-bar--grade-buttons {
  display: flex;
  padding: 0px 20px 10px 20px;
  justify-content: space-around;
}

// search by lesson type select with options
.search-bar--lesson-type {
  height: 23px;
  width: 300px;
  cursor: pointer;
  @include roboto-reg-12px;
  color: $darker-gray;
  padding: 0px 6px;
  background-color: $white;
  border: 1px solid $white;
  margin-top: 1px;

  @include for-lg {
    width: 280px;
  }
  @include for-md-down {
    width: 100%;
    margin-bottom: 10px;
  }

  option {
    @include roboto-reg-12px;
    color: $darker-gray;
  }
}

// search bar submit button
.search-bar--submit {
  width: 35px;
  height: 35px;
  background-color: $sapphire;
  color: $white;
  border-radius: 35px;
  margin-left: auto;
  margin-right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }
}


// search bar results
.search-bar--results {
  display: flex;
  align-items: center;
}

.search-bar--results:before {
  border-bottom: 1px dashed $white;
  border-left: 1px dashed $white;
  content: "";
  width: 20px;
  height: 30px;
  margin-top: -20px;
  margin-left: 10px;
  margin-right: 10px;
}

.search-bar--count {
  color: $white;
  @include roboto-light-22px;
  padding-top: 4px;

  strong {
    @include roboto-bold-22px;
    margin-right: 4px;
  }
  span {
    @include roboto-light-22px;
  }
}

.search-bar--clear {
  @include roboto-bold-12px;
  color: $white;
  cursor: pointer;
  margin-left: 30px;
  padding-top: 6px;

  &:hover {
    color: $icy-blue;
  }
}