// ----------------------------------------
// Helper Classes and Mixins
// ----------------------------------------

// keeps footer at bottom
.content {
  overflow: auto;
  padding-bottom: 45px; //footer height
}

// adjust content for fixed header
@mixin fixed-header-margin {
  margin-top: 115px; //fixed header height
}

// adjust content for fixed header with no search (example: login screen)
@mixin fixed-header-margin-no-search {
  margin-top:60px; //fixed header height
}

// for user with anchors to adjust for fixed header
@mixin fixed-header-anchor {
  display: block;
  position: relative;
  top: -125px; // negative fixed header height and 10px for padding
  visibility: hidden;
}

// space around a vertical line/pipe:  |
// replacing the need to use "&nbsp;&nbsp;"
.space-pipe {
  padding: 0px 10px;
}
