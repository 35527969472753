// ----------------------------------------
// Dashboard:
//    What's New Section
// ----------------------------------------

// -- dashboard whats new --
.dash-whatsnew {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dash-whatsnew h2 {
  @include roboto-bold-34px;
  color: $black;
  margin: 0px;
}

.dash-whatsnew--card {
  @extend .col-xl-4;
  @extend .col-md-6;
  margin-top: 16px;
}

.dash-whatsnew--card .card {
  height: 100%;
  border: 1px solid $mid-gray;
  padding: 6px;
  flex-direction: row;
}

.dash-whatsnew--card .card img {
  height: 100%;
  object-fit: cover;
  width: 115px;
  margin-right: 10px
}

.dash-whatsnew--card .card-details {
  display: flex;
  flex-direction: column;
}

.dash-whatsnew--card .card-details h3 {
  @include roboto-bold-15px;
  margin-top: 10px;
  margin-bottom: 10px
}

.dash-whatsnew--card .card-description {
  @include roboto-reg-12px;
  margin-top: 10px;
}

.dash-whatsnew--card .card-footer {
  margin-top: auto;
  background-color: $white;
  border: none;
  padding: 0px 0px 10px 0px;
}