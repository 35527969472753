// ----------------------------------------
// thinkLibrary:
//    All Lesson Types
// ----------------------------------------

// -- All Lesson Types Cards --
.lib-lesson-types .anchor {
  @include fixed-header-anchor;
}

.lib-lesson-types--cards h2 {
  background-color: $icy-blue;
  @include roboto-reg-12px;
  display: flex;
  align-items: center;
  padding: 3px 14px;

  svg {
    @include icon-10px;
  }
}

.lib-lesson-types--container {
  margin: 0px 40px 10px 40px;
}

.lib-lesson-types--row {
  @extend .row;
  @extend .row-cols-1;
  @extend .row-cols-md-2;
  @extend .gx-5;
  @extend .gy-2;
}

.lib-lesson-types--card {
  @extend .card;
  border: 1px solid $mid-gray;
  border-left: 5px solid $southwestern-orange;
  height: 100%;
  min-height: 115px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @include for-lg-down {
    flex-direction: column;
  }
}

.lib-lesson-types--card-details h3 {
  @include roboto-med-19px;

  span {
    @include roboto-reg-19px;
    color: $southwestern-orange;
  }
}

.lib-lesson-types--card-details p {
  @include roboto-reg-12px;
  color: $darker-gray;
}

.lib-lesson-types--card .button-primary {
  align-self: center;
  margin-left: 20px;

  @include for-lg-down {
    align-self: flex-end;
  }
}

// thinkLibrary background image - always follows Lesson Types
.lib-bgimg {
  height: 300px;
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
  }
}