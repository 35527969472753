// ----------------------------------------
// Footer Styles
// ----------------------------------------

.footer {
  @include roboto-reg-15px;
  background-color: $deep-blueberry;
  color: $white;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -45px;
  height: 45px;
  clear: both;
  @include for-xs-only {
    font-size: 12px;
  }
}

.footer .container {
  display: flex;
  align-items: center;
}

.footer a {
  @include roboto-med-15px;
  color: $white;
  text-decoration: none;

  @include for-xs-only {
    display: none;
  }
}

.footer .space-pipe {
  color: $sapphire;

  @include for-xs-only {
    display: none;
  }
}

.footer .logout-link {
  @extend .ms-auto;

  @include for-xs-only {
    display: block;
    font-size: 12px;
  }
}