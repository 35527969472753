// ----------------------------------------
// thinkSupport:
//    thinkSupport Sidebar
// ----------------------------------------

// -- thinksupport sidebar --
.thinksupport--sidebar {
  min-height: calc(100vh - 160px);
  margin-left: -165px;
  transition: margin 0.25s ease-out;
  background-color: $pale-gray;

  @include for-sm-up {
    margin-left: 0;
  }
}

.thinksupport--sidebar .list-group {
  width: 165px;
  padding: 10px 0px;
}

.thinksupport--sidebar .list-group a {
  @include roboto-med-12px;
  color: $sapphire;
  text-decoration: none;
  padding: 10px;
}

.thinksupport--sidebar .list-group a.active {
  background-color: $white;
  border-left: 5px solid $cactus-green;
  color: $black;
  @include roboto-bold-12px;
}

body.thinksupport--sidebar-toggled .thinksupport--wrapper .thinksupport--sidebar {
  margin-left: 0;
}
