// ----------------------------------------
// Error Pages:
//    Error Pages - 404, 500, etc
// ----------------------------------------

// -- error page --
.error-page {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;
}

.error-page--message {
  max-width: 815px;
  min-height: 628px;
  margin: 0 auto;
  background-image: url('../img/error-page-bg.jpg');
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  padding: 160px 90px;
  justify-content: space-between;

  @include for-sm-down {
    display: block;
    padding: 20px;
    width: 100%;
    height: auto;
    min-height: 0px;
    background-image: none;
  }
}

.error-page--message .message-left, 
.error-page--message .message-right {
  width: 40%;

  @include for-sm-down {
    width: 100%
  }
}

.error-page--message .message-left h1 {
  @include roboto-bold-80px;
  color: $dark-red;

  @include for-md {
    font-size: 60px;
  }
}

.error-page--message .message-left h1 small {
  @include roboto-reg-19px;
  color: $darker-gray;
  display: block;
}

.error-page--message .message-right h2 {
  @include roboto-bold-34px;
  color: $darker-gray;

  @include for-md {
    font-size: 22px;
    line-height: 28px;
  }
  @include for-sm-down {
    margin-top: 20px;
  }
}

.error-page--message .message-right p {
  @include roboto-reg-15px;
  color: $darker-gray;
}

.error-page--message .message-right p a {
  color: $sapphire;
  @include roboto-med-15px;
  text-decoration: none;
}