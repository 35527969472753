// ----------------------------------------
// Teacher Profile:
//    user information and grade settings
// ----------------------------------------

// -- teacher profile --
.teacher-profile {
  @include fixed-header-margin;
  padding-top: 20px;
  padding-bottom: 20px;
}

.teacher-profile--wrapper {
  @extend .row;
  background-color: $pale-gray;
  padding: 16px 16px 30px 16px;
}

.teacher-profile h1 {
  @include roboto-bold-34px;
  margin-bottom: 10px;

  @include for-md-down {
    margin-bottom: 0px;
  }
}

.teacher-profile--col {
  @extend .col-lg-6;

  @include for-md-down {
    margin-top: 20px;
  }
}

.teacher-profile--full {
  @extend .col-lg-12;
}

.teacher-profile--user-settings,
.teacher-profile--grade-settings,
.teacher-profile--password-confrimation {
  background-color: $white;
  padding: 20px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.teacher-profile--user-settings h2 {
  @include roboto-bold-22px;
  margin-bottom: 0px;
}

.teacher-profile--user-settings h3,
.teacher-profile--grade-settings h3 {
  @include roboto-med-15px;
  color: $dark-gray;
  display: flex;
  align-items: center;
}
.teacher-profile--user-settings h3 {
  margin-bottom: 6px;
  margin-top: 30px;
}

.teacher-profile--user-settings h3 svg,
.teacher-profile--grade-settings h3 svg {
  @include icon-20px;
  margin-right: 20px;
  fill: $cactus-green;
}


.teacher-profile--password-confrimation h4 {
  @include roboto-med-15px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.teacher-profile--password-confrimation h4 svg {
  @include icon-20px;
  margin-right: 20px;
  fill: $cactus-green;
}

.teacher-profile--user-settings p,
.teacher-profile--password-confrimation p {
  @include roboto-reg-15px;
}

.teacher-profile--user-settings .user-info {
  @include roboto-reg-22px;
  margin-left: 40px;
  margin-bottom: 6px;
}

.teacher-profile--user-settings .form-error {
  @include roboto-reg-12px;
  color: $dark-red;

  ul {
    padding-left: 0px;
     li {
       list-style-type: none;
     }
  }
}

.teacher-profile--user-settings .form-row .errorlist {
  @include roboto-reg-12px;
  color: $dark-red;
  padding-left: 0px;
  margin-top: 10px;

  li {
    list-style-type: none;
  }
}

.teacher-profile--user-settings .form-row {
  margin-bottom: 20px;
}

.teacher-profile--user-settings .form-row label {
  @include roboto-bold-12px;
}

.teacher-profile--user-settings .form-row input {
  @include roboto-reg-15px;
  background-color: $white;
  border: 1px solid $mid-gray;
  border-radius: 3px;
  @extend .form-control;
  @extend .w-25;
  @include for-md {
    width: 50% !important;
  }
  @include for-sm-down {
    width: 100% !important;
  }
}

.teacher-profile--user-settings .form-row .form-text {
  @include roboto-reg-12px;
  color: $dark-gray;

  ul {
    padding-left: 0px;
    li {
      list-style-type: none;
    }
  }
}

.teacher-profile--user-settings .button-secondary,
.teacher-profile--grade-settings .button-secondary,
.teacher-profile--password-confrimation .button-secondary {
  margin-top: auto;
  max-width: 152px;

  @include for-md-down {
    margin-top: 40px;
  }
}

.teacher-profile--grade-settings p {
  @include roboto-reg-12px;
  color: $darker-gray;
}

.teacher-profile--grade-settings ul.display-grades {
  padding: 0px;
}

.teacher-profile--grade-settings ul.display-grades li {
  list-style-type: none;
  @include roboto-bold-15px;
  margin-bottom: 6px;
}

.teacher-profile--grade-settings ul.display-grades svg {
  @include icon-18px;
  margin-right: 6px;
  fill: $cactus-green;
}

.teacher-profile--grade-settings ul.edit-grades {
  padding: 0px;
  columns: 2;
  max-width: 70%;

  @include for-xs-only {
    columns: 1;
    max-width: 100%;
  }
}

.teacher-profile--grade-settings ul.edit-grades li {
  list-style-type: none;
  padding: 0px 4px;
  margin-bottom: 6px;
  border: 1px solid transparent;
}

.teacher-profile--grade-settings ul.edit-grades li a {
  @include roboto-med-15px;
  color: $sapphire;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacher-profile--grade-settings ul.edit-grades li a svg {
  display: none;
}

.teacher-profile--grade-settings ul.edit-grades li.selected {
  border: 1px solid $black;
}

.teacher-profile--grade-settings ul.edit-grades li.selected a {
  color: $black;
  @include roboto-bold-15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacher-profile--grade-settings ul.edit-grades li.selected a svg {
  display: inline;
  @include icon-10px;
}



.teacher-profile--grade-settings .button-primary {
  max-width: 152px;
}

.teacher-profile--grade-settings .edit-grades-buttons {
  @include roboto-reg-12px;
  color: $dark-gray;
  background-color: $pale-gray;
  padding: 4px 10px;
  margin: 20px 0px;

  .button-secondary-small {
    max-width: 50px;
    width: 50px;
    height: 22px;
    min-height: 22px;
    margin-left: 6px;
    margin-right: 0px;
  }
}